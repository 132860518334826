/* Global Styles */
body {
    font-family: 'Outfit', sans-serif; /* Apply Outfit font globally */
    background-color: black; /* Black background for the entire page */
    color: white; /* Default text color */
    margin: 0; /* Remove default margin */
    max-width: 1200px; /* Set maximum width for the body */
    margin: 0 auto; /* Center the body horizontally */
    overflow-x: hidden; /* Hide horizontal overflow */
}

.contact-page {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content horizontally */
    justify-content: center; /* Center content vertically */
    min-height: 90vh; /* Minimum height to fill the screen */
    padding: 20px; /* Padding for overall spacing */
    background-color: black; /* Background color */
    width: 100%;
}

.contact-header {
    width: 100%; /* Full width for header */
    text-align: center; /* Center text */
    margin: 0px 0px;
}

p.contact {
    font-size: 18px;
    font-weight: 400;
    margin: 12px 0px;
}

p.info {
    font-size: 20px;
    font-weight: 500;
    margin: 012px 0px;
}

.contact-us {
    font-weight: 700; /* Set font weight to 700 for the entire text */
    color: white; /* Default text color */
    font-size: 48px; /* Set font size */
    margin: 48px 0; /* Margin for spacing */
}

.orange-text {
    font-weight: 900; /* Set weight to 900 for 'Orange' */
    color: #EB343A; /* Set color for 'Orange' */
}

.contact-content {
    flex: 1; /* Allow content to grow */
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Center items */
    justify-content: flex-start; /* Center content vertically within the content area */
    width: 100%;
    max-width: 393px;
}