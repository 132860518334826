.blog-image {
    width: 100%; /* Make the image full width of the blog post */
    height: auto; /* Maintain the aspect ratio */
    border-radius: 8px; /* Optional: Add border radius for rounded corners */
    margin-bottom: 32px; /* Space between image and text */
    display: block; /* Change to block for centering */
    margin-left: auto; /* Center horizontally */
    margin-right: auto; /* Center horizontally */
}


.blog.single {
    display: flex; /* Make it a flex container */
    flex-direction: column; /* Stack items vertically */
    justify-content: center; /* Center items horizontally */
    align-items: center; 
    width: 100%; /* Make the image full width of the blog post */
}


.post-title.blog {
    font-size: 48px; 
    font-weight: 700; 
    margin: 4px 0; /* Example for reduced spacing */
}

.post-date.blog {
    font-size: 14px; /* Smaller font size for date */
    color: #D7D8E5; /* Light color for date */
}

.post-excerpt.blog {
    font-size: 28px; /* Font size for the excerpt */
    margin-top: 24px; /* Spacing around the excerpt */
    margin-bottom: 48px; /* Spacing around the excerpt */
    font-weight: 700;
}


.orange{
    color: #EB343A;
    font-weight: 700;
}

.green{
    color: #ffd500;
    font-weight: 700;
}


.brand{
    font-weight: 900;
}

.para{
    font-weight: 400;
    font-size: 24px;
}

.sect-title{
    font-weight: 700;
    font-size: 32px;
    margin-top: 48px;
    margin-bottom: 48px;
}

.sect-title.m{
    font-weight: 700;
    font-size: 28px;
    margin-top: 56px;
    margin-bottom: 36px;
}

/* Adjust the layout on smaller screens */
@media (max-width:700px) {
    .para{
        font-size: 18px;
    }


    .post-title.blog {
        font-size: 32px; 
        font-weight: 700; 
        margin: 4px 0; /* Example for reduced spacing */
    }

    .post-excerpt.blog {
        font-size: px; /* Font size for the excerpt */
        margin-top: 24px; /* Spacing around the excerpt */
        margin-bottom: 48px; /* Spacing around the excerpt */
        font-weight: 700;
    }
}