.footer {
    display: flex;
    flex-direction: column; /* Stack rows vertically */
    align-items: center; /* Center items horizontally */
    padding-bottom: 48px; /* Padding at the bottom of the footer */
    background-color: black; /* Background color for footer */
    color: white; /* Text color */
    width: 100%; /* Ensure footer takes full width of parent */
    box-sizing: border-box; /* Include padding in the element's total width */
}

.social-icons {
    display: flex; /* Use flexbox for social icons */
    gap: 16px; /* Space between icons */
}

.footer-links {
    display: flex; /* Use flexbox for footer links */
    gap: 16px; /* Space between buttons */
    margin-top: 1px; /* Space above footer links */
    justify-content: center; /* Center the buttons */
    flex-wrap: wrap; /* Allow the items to wrap onto new lines if there's not enough space */
}

.button.ghost.footer {
    background: transparent; /* Transparent background for ghost buttons */
    color: white; /* Text color */
    border: none; /* No border */
    text-decoration: underline; /* Underline text */
    cursor: pointer; /* Pointer cursor on hover */
    padding: 4px 12px; /* Adjust padding for better button size */
    min-width: 100px; /* Set a minimum width to prevent wrapping */
    white-space: nowrap; /* Prevent text from wrapping */
}

/* Hover effect for ghost buttons */
.button.ghost.footer:hover {
    background: transparent; 
    color: #EB343A; /* Change color on hover */
}


.separator {
    height: 1px; /* Set the height to 1 pixel */
    background-color: rgba(255, 255, 255, 0.15); /* Set the background color to white with 15% transparency */
    margin: 48px 0; /* Add vertical spacing above and below */
    width: 100%; /* Ensure it takes full width */
}

@media (max-width: 700px) {
    .footer-links {
        display: flex;
        gap: 16px; /* Space between buttons */
        margin-top: 1px; /* Space above footer links */
        margin-bottom: 16px;
        align-items: center; /* Center the items horizontally */
        justify-content: space-evenly;
    }
}

