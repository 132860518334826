.blog-page {
    display: flex;
    flex-direction: column;
}

.blog-content {
    flex: 1; /* Allow content to grow */
    padding: 48px; /* Padding around the main content */
    background-color: black; /* Background color for the main content */
    color: white; /* Text color */
}

.most-recent {
    font-size: 24px; /* Font size for "Most Recent" text */
    font-weight: 700; /* Bold weight */
    margin: 16px 0; /* Spacing around the "Most Recent" text */
    text-align: left; /* Align text to the left */
}

.blog-posts {
    display: flex;
    flex-direction: column; /* Stack posts vertically */
    gap: 24px; /* Space between posts */
}

.blog-post {
    padding: 24px; /* Padding for post */
    max-width: 300px; /* Max width for blog posts */
    margin-left: 0; /* Align to the left */
    border: none; /* Remove border */
    background-color: transparent; /* Default background */
    cursor: pointer;
}

.blog-post:hover {
    background-color: #0c0c0c; /* Change background color on hover */
    border-radius: 8px; /* Add border radius for rounded corners */
}

.post-image {
    width: 100%; /* Make the image full width of the blog post */
    height: auto; /* Maintain the aspect ratio */
    border-radius: 8px; /* Optional: Add border radius for rounded corners */
    margin-bottom: 12px; /* Space between image and text */
}

.post-title {
    font-size: 28px; 
    font-weight: 700; 
    margin: 4px 0; /* Example for reduced spacing */
}

.post-title.promoted-info {
    font-size: 48px; /* Title font size for posts */
    font-weight: 900; 
    margin: 4px 0; /* Example for reduced spacing */
}

.post-date {
    font-size: 14px; /* Smaller font size for date */
    color: #D7D8E5; /* Light color for date */
}

.post-excerpt {
    font-size: 18px; /* Font size for the excerpt */
    margin: 8px 0; /* Spacing around the excerpt */
}

.post-excerpt.promoted-info {
    font-size: 28px; /* Font size for the excerpt */
    margin: 8px 0; /* Spacing around the excerpt */
    font-weight: 700;
}

.promoted-blog {
    display: flex; /* Use flexbox */
    flex-direction: row; /* Align items in a row */
    justify-content: center; /* Center the content horizontally */
    align-items: flex-start; /* Align items to the top */
    margin-bottom: 96px; /* Add margin below the promoted blog */
    cursor: pointer;
}

.promoted-info {
    display: flex; 
    flex-direction: column; /* Stack the text vertically within the info section */
    justify-content: flex-start; /* Align items to the start (left) */
    color: white; /* Set text color to white */
    align-items: flex-start; /* Ensure items are aligned to the left */
}

.promoted-image {
    width: 40vw; /* Set the width to 40% of the viewport width */
    height: auto; /* Maintain the aspect ratio */
    border-radius: 8px; /* Optional: Add border radius for rounded corners */
    margin-right: 16px; /* Space between image and text */
}



/* Adjust the layout on smaller screens */
@media (max-width:700px) {
    .promoted-blog {
        flex-direction: column; /* Ensure items are in a row on larger screens */
        align-items: center; /* Center items vertically on small screens */
    }

    .promoted-image {
        max-width: 100%; /* Full width on small screens */
    }
    .post-title.promoted-info {
        font-size: 28px; 
        margin: 4px 0; /* Example for reduced spacing */
    }

    .promoted-image {
        width: 100%; /* Make the image full width of the blog post */
        height: auto; /* Maintain the aspect ratio */
        border-radius: 8px; /* Optional: Add border radius for rounded corners */
        margin-bottom: 12px; /* Space between image and text */
    }

    .blog-posts {
        flex-direction: column; /* Stack posts vertically */
        align-items: center; /* Center children horizontally */
    }
}

.underline {
    height: 1px; /* Set the height to 1 pixel */
    background-color: rgba(255, 255, 255, 0.15); /* Set the background color to white with 15% transparency */
    margin: 12px 0; /* Add vertical spacing above and below */
    margin-bottom: 48px;
    width: 100%; /* Ensure it takes full width */
}

.button.ghost.blog {
    background: transparent; /* Transparent background for ghost buttons */
    color: white; /* Text color */
    border: none; /* No border */
    text-decoration: underline; /* Underline text */
    cursor: pointer; /* Pointer cursor on hover */
    padding: 0px;
    align-self: flex-start; /* Align the button to the start (left) */
    margin-top: 8px; /* Optional: Add space above the button */
}

/* Hover effect for ghost buttons */
.button.ghost.blog:hover {
    background: transparent; 
    color: #EB343A; /* Change color on hover */
}

.link {
    text-decoration: none; /* Remove underline */
    color: inherit; /* Inherit the text color from the parent */
}

.link:hover {
    color: inherit; /* Maintain color on hover */
}
