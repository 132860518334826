.text-input-container {
    margin-bottom: 12px; /* Space between inputs */
    margin-top: 12px; /* Space between inputs */
    max-width: 396px; /* Set max width to 396 pixels */
    width: 100%; /* Make input fill the width of the parent container */
    text-align: left; /* Align text to the left */
}

.text-input-title {
    font-weight: bold;
    text-align: left; /* Align title text to the left */
    margin-bottom: 24px; /* Add space beneath the title */
}

.text-input-wrapper {
    margin-top: 12px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    border: 1px solid #8A8C93; /* Set border color */
    border-radius: 8px; /* Rounded corners */
    padding: 8px; /* Padding inside input */
    transition: border-color 0.2s ease; /* Smooth transition for border color */
  }
  
  .text-input {
    border: none; /* Remove default border */
    outline: none; /* Remove outline */
    padding: 8px;
    height: 32px; /* Set height to 32 pixels */
    color: #FFFFFF; /* Set text color to white for better contrast on black */
    background-color: black; /* Set background color to black */
    width: 100%; /* Make input fill the width of the parent container */
    box-sizing: border-box; /* Ensure padding is included in the width */
    font-family: 'Outfit', sans-serif; /* Apply Outfit font to input text */
    font-size: 16px; /* Adjust the font size for input text */
    color: #FFFFFF; /* Set text color to white for better contrast on black */
  }
  

.send-button {
    background-color: #EB343A; /* Button color */
    color: white; /* Button text color */
    border: none;
    border-radius: 4px; /* Rounded corners */
    cursor: pointer;
    padding: 8px 12px; /* Padding for the button */
    margin-left: 8px; /* Space between input and button */
    width: 100%; /* Make button fill the width of the container */
}

.error-message {
    color: #EB343A; /* Error text color */
    margin-top: 12px; /* Space above error */
    text-align: left; /* Align error message to the left */
}

.text-input-title,
.error-message {
  font-family: 'Outfit', sans-serif; /* Apply Outfit font to title and error message */
  font-size: 14px; /* Adjust the font size as needed */
  color: white; /* Ensure the text color contrasts with the background */
}
