@import url('../fonts/fonts.css');

/* Global Styles */
body {
    font-family: 'Outfit', sans-serif; /* Apply Outfit font globally */
    background-color: black; /* Black background for the entire page */
    color: white; /* Default text color */
    margin: 0; /* Remove default margin */
    max-width: 1200px; /* Set maximum width for the body */
    margin: 0 auto; /* Center the body horizontally */
    overflow-x: hidden; /* Hide horizontal overflow */
    
}

.main-title {
    font-size: clamp(54px, 8vw, 96px); /* Responsive font size with min 48px, max 96px */
    font-weight: 600; /* Set font weight to bold */
    text-align: center; /* Center the text */
    margin: 32px; /* Margin to space around the title */
    width: 100%;
    max-width: 1000px;
}

/* For small screens (less than 600px), hide the line break */
@media (max-width: 600px) {
    .main-title {
        font-size: clamp(36px, 10vw, 54px); /* Smaller font size for small screens */
    }

    /* Hide the <br /> on small screens */
    .main-title br {
        display: none;
    }
}




.title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

}

.bitcoin-title {
    font-size: 32px; /* Set font size for Bitcoin text */
    font-weight: bold; /* Set font weight to bold */
    text-align: left; /* Ensure title aligns left */
}

.experience-money {
    font-size: 48px; /* Set font size to 48px */
    font-weight: bold; /* Set font weight to bold */
    margin: 0px 0; /* Add margin for spacing */
    text-align: left; /* Ensure this text aligns left */
}

.bitcoin-description {
    font-size: 24px; /* Set font size to 24px */
    font-weight: 400; /* Set font weight to 400 */
    color: #D7D8E5; /* Set text color to D7D8E5 */
    text-align: left; /* Align text to the left */
    margin-top: 24px; /* Add margin for spacing above the paragraph */
}

/* Buttons */
.button {
    font-family: 'Outfit', sans-serif; /* Ensure buttons use the Outfit font */
    color: white; /* Default text color for buttons */
    border: none; /* Remove border for default and primary buttons */
    padding: 0 16px; /* Increased horizontal padding to 16 pixels */
    height: 48px; /* Increased height to 48 pixels */
    display: inline-flex; /* Use flexbox for better alignment */
    align-items: center; /* Center content vertically */
    border-radius: 24px; /* Increased border radius to 24 pixels */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 20px; /* Increased font size to 20 pixels */
    font-weight: bold; /* Set font weight to bold */
}

.button-row {
    display: flex; /* Flexbox for buttons */
    gap: 24px; /* Space between buttons */
    justify-content: center; /* Center the buttons horizontally */
}

.button.primary {
    background-color: #EB343A; /* Primary button background */
}

.button.primary:hover {
    background-color: rgb(240, 10, 41); 
}

.button.secondary {
    background-color: #000000; /* Secondary button background */
    border: 1px solid #525458; /* 1px border with specified color */
}

.button.secondary:hover {
    background-color: #333333; /* Dark grey fill on hover */
}

.button.ghost {
    background-color: transparent; /* No background */
    color: white; /* White text color */
    border: none; /* No outline */
}

.button.ghost:hover {
    background-color: rgba(36, 36, 36, 0.1); /* Light background on hover */
    color: white; /* Ensure text stays white on hover */
    text-decoration: underline; /* Underline text */
}

/* Content Styles */
.content {
    text-align: center; /* Center align content */
    padding: 48px 0;
    width: 100%;
    align-items: center;
    justify-content: center;
    align-items: center;
}

.separator {
    height: 1px; /* Set the height to 1 pixel */
    background-color: rgba(255, 255, 255, 0.15); /* Set the background color to white with 15% transparency */
    margin: 48px 0; /* Add vertical spacing above and below */
    display: block;
}

.top-separator {
    height: 24px; /* Set the height for spacing */
  }

.three-phone {
    max-width: 100%; /* Full width for smaller screens */
}

  
  @media (max-width: 600px) {
    .three-phone {
        display: none;
      }
    
      .top-separator {
        height: 24px;  /* Adds 24px spacing */
        background-color: transparent; /* Removes the line */
      }
  }
  

@media (max-width: 768px) {
    .three-phone {
        max-width: 100%; /* Full width for smaller screens */
        margin: 48px auto 0; /* Maintain the top margin on smaller screens */
    }

    /* Buttons */
    .button {
        padding: 0 14px; /* Increased horizontal padding to 16 pixels */
        height: 36px; /* Increased height to 48 pixels */
        font-size: 16px; /* Increased font size to 20 pixels */
    }

    .button-row {
        display: flex; /* Flexbox for buttons */
        gap: 16px; /* Space between buttons */
        justify-content: center; /* Center the buttons horizontally */
    }

    .main-title {
        font-weight: 800; 
    }

    .text-download {
        display:none;
    }

    .content {
        padding: 18px 0; /* Smaller padding on screens <= 600px */
    }
}

/* USP Container */
.usp-container {
    display: flex;
    flex-direction: row; /* Default to row layout for larger screens */
    align-items: center; /* Vertically center items */
    justify-content: center; /* Align content to the left */
    gap: 24px; /* Add space between elements */
    width: 100%; /* Ensure full width */
    max-width: 1200px; /* Limit maximum width */
    margin: 0 auto; /* Center the container horizontally */
    padding: 0 48px; /* Optional: Add padding for left and right spacing */
    box-sizing: border-box; /* Include padding in the width calculation */
}

.usp-text {
    flex: 1; /* Allow text to take remaining space */
    max-width: 500px; /* Set maximum width for text */
    text-align: left; /* Left alignment */
    width: 100%;
}

.usp-header {
    display: flex; /* Use flexbox for the header */
    align-items: center; /* Center items vertically */
    margin-bottom: 0px; /* Space between header and text */
}

.usp-header > *:not(:last-child) {
    margin-right: 12px; /* Add 12px space between elements except the last one */
}

.usp-icon {
    width: 32px; /* Set icon width */
    height: auto; /* Maintain aspect ratio */
    margin-right: 8px; /* Space between icon and text */
}

.usp-image {
    width: 100%;
    max-width: 33%; /* Ensure it doesn't exceed parent width */
}

/* Download Section */
.download-title {
    font-size: 32px; /* Font size for the download title */
    font-weight: black; /* Black weight for the title */
    color: white; /* Orange color */
    text-align: center; /* Center align */
    margin: 24px 0; /* Margin above and below the title */
}

.download-cards {
    display: flex;
    justify-content: center; /* Center the cards */
    gap: 32px; /* Space between cards */
    margin-top: 24px; /* Space above the cards */
    flex-wrap: wrap; /* Allow cards to wrap to the next line */
}

.download-card {
    display: flex; /* Use flexbox for layout */
    flex-direction: column; /* Stack items vertically */
    justify-content: flex-start; /* Align items at the start of the card */
    align-items: flex-start; /* Align items to the left */
    padding: 16px; /* Optional padding for inner spacing */
    max-width: 230px; /* Set maximum width to 230 pixels */
    width: 100%; /* Full width for responsiveness */
    border-radius: 12px; /* Rounded corners (adjust as needed) */
    border: 1px solid #525458; /* Set outline to 1px and color to #525458 */
}

.download-card-title {
    color: #FFFFFF; /* Color for the card title */
    font-size: 24px;
    margin: auto 0;
}

.download-icon {
    margin-bottom: 12px; /* Space between icon and title */
}

.download-card-description {
    margin: 18px 0; /* Space above and below the description */
    text-align: left;
}

.download-button {
    height: 32px; /* Set the height */
    padding: 0 12px; /* Horizontal padding */
    font-size: 16px;
}

/* Hover Styles */
.download-button:hover {
    background-color: #000; /* Same as secondary button hover */
}

/* Media Queries */
@media (max-width: 700px) {
    .experience-money {
        font-size: 38px; /* Set font size to 32px */
        text-align: left; /* Align text to the left on smaller screens */
        width: 100%; /* Optional: Ensure it takes full width */
    }

    .bitcoin-description {
        font-size: 20px; /* Set font size to 24px */
    }

    .bitcoin-title {
        font-size: 28px; /* Set font size for Bitcoin text */
    }

    .usp-container {
        display: flex; /* Make it a flex container */
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center items horizontally */
        text-align: center; /* Center text */
        width: 100%; /* Ensure full width */
        margin: 0 auto; /* Center the container itself if it has a max width */
        padding: 0 48px; /* Add 24px padding to the left and right */
    }
    
    
    .usp-image {
        margin: 0 auto; /* Center the container itself if it has a max width */
        width: 100%; /* Set to a larger width on small screens */
        max-width: 90%; /* Ensure it doesn't exceed parent width */
    }
    

    .download-cards {
        flex-direction: column; /* Stack cards vertically on small screens */
        align-items: center; /* Center cards horizontally when stacked */
    }

    .download-card {
        width: 100%; /* Full width on smaller screens */
        margin-bottom: 16px; /* Space between cards */
    }
}

.textmark {
    transform: translateY(10%);
    height: 84px;
    width: auto;
}


.textmark.lg {
    transform: translateY(14%);
    height: 48px;
    width: auto;
}


.textmark.md {
    transform: translateY(14%);
    height: 36px;
    width: auto;
}


.textmark.sm {
    transform: translateY(10%);
    height: 48px;
    width: auto;
}