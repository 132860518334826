.privacy-policy-page {
    display: flex; /* Use flexbox for layout */
    flex-direction: column; /* Stack items vertically */
    min-height: 100vh; /* Ensure it takes at least full screen height */
}

.privacy-policy-content {
    flex-grow: 1; /* Allow the main content to grow and take available space */
    display: flex; /* Use flexbox for centering */
    flex-direction: column; /* Stack items vertically */
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    text-align: center; /* Center align text */
    padding: 20px; /* Add padding around the content */
}

.privacy-policy-title {
    font-size: 32px; /* Set font size for the title */
    font-weight: 700; /* Set font weight to bold */
    color: white; /* Set text color to white */
    margin-bottom: 20px; /* Add space below the title */
}

.privacy-policy-text {
    font-size: 18px; /* Set font size for the text */
    color: white; /* Set text color to white */
    max-width: 400px; /* Limit the width for better readability */
    line-height: 1.5; /* Increase line height for readability */
    text-align: left;
}
