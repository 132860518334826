.newsletter-card {
    background-color: black; /* Set background to black */
    padding: 24px; /* Space inside the card */
    border-radius: 12px; /* Rounded corners */
    color: white; /* Default text color */
    display: flex; /* Enable flexbox layout */
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Center items vertically (if needed) */
    flex-direction: column; /* Stack items vertically */
    max-width: 396px; /* Set max width to 396 pixels */
    margin: 0 auto; /* Center the card in the parent container */
}


  
  .error-message {
    color: #EB343A; /* Error text color */
  }
  
  
  .email-input {
    width: 100%; /* Full width */
    padding: 12px; /* Padding for the input */
    border: 1px solid #ccc; /* Border color */
    border-radius: 8px; /* Rounded corners for input */
    margin-bottom: 16px; /* Space between input and button */
  }

  .button.newsletter.primary {
    height: 48px;          /* Set the height */
    padding: 0 16px;      /* Horizontal padding only */
    border-radius: 24px;   /* Border radius */
    font-size: 20px;       /* Font size */
    width: 100%;           /* Make button fill the container */
    display: block;        /* Ensure it behaves as a block element */
    max-width: 396px;
}

.button.newsletter.disabled {
    color: #000;           /* Black text */
    background-color: #8A8C93; /* Fill color for disabled state */
    cursor: not-allowed;   /* Change cursor to indicate disabled state */
    max-width: 396px;
}

  