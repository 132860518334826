.header {
  display: flex; /* Use flexbox for layout */
  justify-content: space-between; /* Space out items */
  align-items: center; /* Center items vertically */
  padding: 12px 48px;
  width: 100%;
  box-sizing: border-box; /* Include padding in width calculation */ 
  position: sticky; /* Stick to the top */
  top: 0; /* Offset from the top */
  z-index: 1000;
  background-color: black;
}

.logo {
  height: 84px; /* Default logo size */
  width: auto; /* Maintain aspect ratio */
}

/* Switch logo size on smaller screens */
@media (max-width: 700px) {
  .logo {
      height: 48px; /* Smaller logo size on screens <= 700px */
  }

  .header {
      padding: 24px; /* Change padding to 24px for smaller screens */
  }
}
