@font-face {
  font-family: 'Outfit';
  font-weight: 900; /* Black */
  src: url('./Outfit-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Outfit';
  font-weight: 800; /* ExtraBold */
  src: url('./Outfit-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Outfit';
  font-weight: 700; /* Bold */
  src: url('./Outfit-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Outfit';
  font-weight: 200; /* ExtraLight */
  src: url('./Outfit-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Outfit';
  font-weight: 300; /* Light */
  src: url('./Outfit-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Outfit';
  font-weight: 500; /* Medium */
  src: url('./Outfit-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Outfit';
  font-weight: 400; /* Regular */
  src: url('./Outfit-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Outfit';
  font-weight: 600; /* SemiBold */
  src: url('./Outfit-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Outfit';
  font-weight: 100; /* Thin */
  src: url('./Outfit-Thin.ttf') format('truetype');
}
